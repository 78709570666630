import React, { useContext } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import Markdown from 'react-markdown';

import { SEO, Button, Jumbotron, FullWidthBar } from '@components';
import { SquiglyLine, IconSprout } from '@components/icons';
import { NewsletterModalContext } from '../context/NewsletterModalContextProvider';

import styles from './landingPage.module.scss';

const IndexPage = () => {
  const { toggleNewsletterModalHandler } = useContext(NewsletterModalContext);
  const query = useStaticQuery(graphql`
    query {
      smallleaves: file(relativePath: { eq: "small-leaves.png" }) {
        childImageSharp {
          fluid(maxWidth: 378) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      home: allStrapiHomepage {
        nodes {
          seo {
            id
            metaDescription
            metaTitle
          }
          hero {
            description
            mobileDescription
            heroHeader {
              miniTitle
              fullSizedTitle
            }
            heroImage {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 650) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          body {
            button {
              id
              title
              link
            }
            description
            id
            title
            isAltLayout
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 320) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const {
    seo: { metaTitle, metaDescription },
    hero: {
      description,
      mobileDescription,
      heroHeader: { miniTitle, fullSizedTitle },
      heroImage,
    },
    body,
  } = query.home.nodes[0];

  const jumbotronContents = (
    <section className={styles.section}>
      <Jumbotron>
        <div className={styles.jumbotronContainer}>
          <div className={styles.jumbotronTextContainer}>
            <h2 className={styles.jumbotronTitle}>
              <span className={styles.smallHeader}> {miniTitle}</span>
              <IconSprout className={styles.sprout} />
              <br />
              {fullSizedTitle}
            </h2>

            <SquiglyLine className={styles.squiglyLineDivider} />
            <div className={styles.jumbotronParagraph}>
              <span className={styles.normal}>
                <Markdown source={description} escapeHtml={false} />
              </span>
              <span className={styles.mobile}>
                <Markdown source={mobileDescription} escapeHtml={false} />
              </span>
            </div>
          </div>
          <div className={styles.largeLeavesContainer}>
            <Img
              fluid={heroImage.localFile.childImageSharp.fluid}
              alt='Large leaves'
            />
          </div>
        </div>
      </Jumbotron>
    </section>
  );

  const bodyContents = body.map(sectionContent => {
    const {
      isAltLayout,
      id,
      title,
      description,
      button,
      image,
    } = sectionContent;

    return isAltLayout ? ( //Flag to determine orientation of image, description, and leaf
      <section className={styles.section} key={id}>
        <div className={styles.sectionContainer}>
          <div className={styles.leavesFullWidthContainer}>
            <div className={styles.leavesRight}>
              <Img
                fluid={query.smallleaves.childImageSharp.fluid}
                alt='Small leaves'
              />
            </div>
            <div className={styles.leavesLeftFlipped}>
              <Img
                fluid={query.smallleaves.childImageSharp.fluid}
                alt='Small leaves'
              />
            </div>
          </div>
          <h2 className={styles.sectionHeader}>{title}</h2>
          <div className={styles.sectionParagraph}>
            <Markdown source={description} escapeHtml={false} />
          </div>
          <div className={styles.buttonWrapper}>
            <Link to={button.link && button.link}>
              <Button title={button.title} />
            </Link>
          </div>
          <div className={styles.sectionImageLeft}>
            <Img
              fluid={image.localFile.childImageSharp.fluid}
              imgStyle={{ objectFit: 'cover' }}
              sizes={{
                ...image.localFile.childImageSharp.fluid,
                aspectRatio: 16 / 10,
              }}
            />
          </div>
        </div>
      </section>
    ) : (
      <section className={styles.section} key={id}>
        <div className={styles.sectionContainer}>
          <div className={styles.leavesFullWidthContainer}>
            <div className={styles.leavesLeft}>
              <Img
                fluid={query.smallleaves.childImageSharp.fluid}
                alt='Small leaves'
              />
            </div>
            <div className={styles.leavesRightFlipped}>
              <Img
                fluid={query.smallleaves.childImageSharp.fluid}
                alt='Small leaves'
              />
            </div>
          </div>
          <div className={styles.sectionImage}>
            <Img
              fluid={image.localFile.childImageSharp.fluid}
              imgStyle={{ objectFit: 'cover' }}
              sizes={{
                ...image.localFile.childImageSharp.fluid,
                aspectRatio: 16 / 10,
              }}
            />
          </div>
          <h2 className={styles.sectionHeader}>{title}</h2>
          <div className={styles.sectionParagraph}>
            <Markdown source={description} escapeHtml={false} />
          </div>
          <div className={styles.buttonWrapper}>
            {button.title === 'Subscribe' ? (
              <Button
                title={button.title}
                onClick={toggleNewsletterModalHandler}
              />
            ) : (
              <Link to={button.link && button.link}>
                <Button title={button.title} />
              </Link>
            )}
          </div>
        </div>
      </section>
    );
  });

  return (
    <>
      <SEO title={metaTitle} description={metaDescription} />
      {jumbotronContents}
      {bodyContents}
      <FullWidthBar>
        <Button
          title={'Subscribe to be inspired'}
          onClick={toggleNewsletterModalHandler}
        />
      </FullWidthBar>
    </>
  );
};

export default IndexPage;
